
import React from "react";
import {Button, Form, Input, Card, Steps} from "antd";
import {connect} from "react-redux";
//import {Link} from "react-router-dom";

import {userDevis} from "../appRedux/actions/Auth";
//import IntlMessages from "util/IntlMessages";

//import InfoView from "components/InfoView";
import WidgetHeader from "components/WidgetHeader/index";

import axios from 'axios';

const {TextArea} = Input;

const FormItem  = Form.Item;
const Step      = Steps.Step;

// https://wa.me/33673218916?text=Je%20suis%20intéressé%20par%20la%20reparation%20

// http://localhost:3000/devis?ville=paris15

class Devis extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      currentModele: 0,
      currentMarque: 0,
      currentMarqueName: 0,
      tabAffiche: [],
      marqueImageList: [],
      modeleImageList: [],
      modele : null,
      tarif : [],
      nom : '',
      email : '',
      telephone_mobile : '',
      IsAffichePrix: false,
      reparation : null,
      IsAutre : false,
      libelleAutre: '',
      message : '',
      cp : '',
      GUID : '',
      IDSite : 1,
      prixAffiche : 0,
      tabMarqueImageList : [
        {
          id: 1,
          image: require('assets/images/APPLE.jpg'),
          name: '  Apple  ',
        },

        {
          id: 2,
          image: require('assets/images/SAMSUNG.jpg'),
          name: 'Samsung',
        },
        {
          id: 3,
          image: require('assets/images/HUAWEI.jpg'),
          name: 'Huawei',
        },
        {
          id: 4,
          image: require('assets/images/autre.png'),
          name: 'Autre',
        }
      ],

      tabModeleListApple : [

        {
          id: 1,
          image: require('assets/images/modele/APPLE/IPHONE11.png'),
          name: 'iPhone 11',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '199',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 343,
               },


          ]
        },
        {
          id: 1,
          image: require('assets/images/modele/APPLE/IPHONE8.png'),
          name: 'iPhone SE 2',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '189',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 565,
               },


          ]
        },
        {
          id: 1,
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          name: 'iPhone X',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '199',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 13,
               },
                {
                 id: 'Batterie',
                 Prix: '79',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 141,
                },
                {
                 id: 'vitre_arriere',
                 Prix: '119',
                 Libelle: ' Remplacement vitre arrière ',
                 image: require('assets/images/modele/REPARATION/vitre_arr.png'),
                 idTarif : 237,
                },
          ]
        },
        {
          id: 2,
          image: require('assets/images/modele/APPLE/IPHONEXR.png'),
          name: 'iPhone Xr',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '199',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 14,
               },
                {
                 id: 'vitre_arriere',
                 Prix: '119',
                 Libelle: ' Remplacement vitre arrière ',
                 image: require('assets/images/modele/REPARATION/vitre_arr.png'),
                 idTarif : 238,
                },
          ]
        },
        {
          id: 3,
          image: require('assets/images/modele/APPLE/IPHONEXS.png'),
          name: 'iPhone Xs',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '249',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 15,
               },
                {
                 id: 'vitre_arriere',
                 Prix: '129',
                 Libelle: ' Remplacement vitre arrière ',
                 image: require('assets/images/modele/REPARATION/vitre_arr.png'),
                 idTarif : 239,
                },
          ]
        },
        {
          id: 4,
          image: require('assets/images/modele/APPLE/IPHONEXSMAX.png'),
          name: 'iPhone XS Max',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '299',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 16,
               },
                {
                 id: 'vitre_arriere',
                 Prix: '139',
                 Libelle: ' Remplacement vitre arrière ',
                 image: require('assets/images/modele/REPARATION/vitre_arr.png'),
                 idTarif : 240,
                },
          ]

        },
        {
          id: 5,
          image: require('assets/images/modele/APPLE/IPHONE8.png'),
          name: 'iPhone 8',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '109',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 11,
               },
                {
                 id: 'Batterie',
                 Prix: '59',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 139,
                },
                {
                 id: 'vitre_arriere',
                 Prix: '99',
                 Libelle: ' Remplacement vitre arrière ',
                 image: require('assets/images/modele/REPARATION/vitre_arr.png'),
                 idTarif : 235,
                },
          ]

        },
        {
          id: 6,
          image: require('assets/images/modele/APPLE/IPHONE8PLUS.png'),
          name: 'iPhone 8 Plus',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '119',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 12,
               },
                {
                 id: 'Batterie',
                 Prix: '59',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 140,
                },
                {
                 id: 'vitre_arriere',
                 Prix: '109',
                 Libelle: ' Remplacement vitre arrière ',
                 image: require('assets/images/modele/REPARATION/vitre_arr.png'),
                 idTarif : 236,
                },
          ]
        },
        {
          id: 7,
          image: require('assets/images/modele/APPLE/IPHONE7.png'),
          name: 'iPhone 7',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '99',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 9,
               },
                {
                 id: 'Batterie',
                 Prix: '49',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 137,
                }
          ]

        },
        {
          id: 8,
          image: require('assets/images/modele/APPLE/IPHONE7PLUS.png'),
          name: 'iPhone 7 Plus',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '109',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 10,
               },
                {
                 id: 'Batterie',
                 Prix: '49',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 138,
                }
          ]

        },
        {
          id: 9,
          image: require('assets/images/modele/APPLE/IPHONE6S.png'),
          name: 'iPhone 6s',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '79',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 7,
               },
                {
                 id: 'Batterie',
                 Prix: '49',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 133,
                }
          ]


        },
        {
          id: 10,
          image: require('assets/images/modele/APPLE/IPHONE6SPLUS.png'),
          name: 'iPhone 6s Plus',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '99',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 8,
               },
                {
                 id: 'Batterie',
                 Prix: '49',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 135,
                }
          ]


        },
        {
          id: 11,
          image: require('assets/images/modele/APPLE/IPHONE6.png'),
          name: 'iPhone 6',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '69',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 5,
               },
                {
                 id: 'Batterie',
                 Prix: '49',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 133,
                }
          ]

        },
        {
          id: 12,
          image: require('assets/images/modele/APPLE/IPHONE6PLUS.png'),
          name: 'iPhone 6 Plus',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '79',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 6,
               },
                {
                 id: 'Batterie',
                 Prix: '49',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 134,
                }
          ]

        },
        {
          id: 13,
          image: require('assets/images/modele/APPLE/IPHONE5S.png'),
          name: 'iPhone 5SE',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '59',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 4,
               },
                {
                 id: 'Batterie',
                 Prix: '39',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 132,
                }
          ]

        },
        {
          id: 14,
          image: require('assets/images/modele/APPLE/IPHONE5S.png'),
          name: 'iPhone 5s',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '59',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 3,
               },
                {
                 id: 'Batterie',
                 Prix: '39',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 131,
                }
          ]

        },
        {
          id: 15,
          image: require('assets/images/modele/APPLE/IPHONE5C.png'),
          name: 'iPhone 5c',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '59',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 2,
               },
                {
                 id: 'Batterie',
                 Prix: '39',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 130,
                }
          ]

        },
        {
          id: 16,
          image: require('assets/images/modele/APPLE/IPHONE5.png'),
          name: 'iPhone 5',
          tarif: [
                {
                 id: 'Ecran',
                 Prix: '59',
                 Libelle: 'Remplacement Ecran',
                 image: require('assets/images/modele/APPLE/IPHONEX.png'),
                 idTarif : 1,
               },
                {
                 id: 'Batterie',
                 Prix: '39',
                 Libelle: 'Remplacement batterie',
                 image: require('assets/images/modele/REPARATION/batterie.png'),
                 idTarif : 129,
                }
          ]

        },

          ],




  }
}


  handleSubmitAutre = (e) => {
    console.log("handleSubmitAutre");
    console.log('autre');
  }

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
    //  this.submitComment();
    }
  };

  updateMessageValue(evt) {
  //  console.log(evt.target.value);

    this.setState({
      message: evt.target.value
    });
  }

  handleSubmit = (e) => {
    console.log("handleSubmit");
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Pas erreur");
        this.state.nom                = values.nom
        this.state.email              = values.email
        this.state.telephone_mobile   = values.telephone_mobile
        this.state.cp                 = values.code_postal

        axios.create({
         headers: {
           'Content-Type': 'application/json',
         }
       });
        console.log('MAIILL OK -> ' + this.state.email)


        if(this.state.IsAutre){

          console.log('Autre ' + this.state.message);
          // 'http://51.178.51.111:8440/sendEmail'
          //  var url_api = 'https://devis.phoner.fr/api/sendEmail';
          //  url_api = 'http://localhost:8070/module_devis/add'
          // https://pro.phonerbusiness.com/api/module_devis/add


          // PROD OK
          //'https://pro.phonerbusiness.com/api/module_devis/add'

          // Local

          try {
            const response = axios.post('https://pro.phonerbusiness.com/api/module_devis/add', {
              IDModele: -1,
              Modele: -1,
              IDTarif : 1648,
              Reparation: this.state.message,
              Prix : -1,
              Nom : this.state.nom,
              Email : this.state.email,
              Tel : this.state.telephone_mobile,
              Marque : 'Autre',
              CP : this.state.cp ,
              GUID : this.state.GUID,
              IDSite : this.state.IDSite,
              });
            console.log('👉 Returned data:', response);
          } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
          }

        this.state.IsAffichePrix  = false;
        this.state.current        = 0;
        this.state.IsAutre        = false;
        this.affichage();


        }else{
          console.log('envoi email ' + this.state.modele.name);


          // http://localhost:8070/module_devis/add
          // https://pro.phonerbusiness.com/api/module_devis/add



          axios.post('https://pro.phonerbusiness.com/api/module_devis/add', {
              IDModele: this.state.modele.name,
              Modele: this.state.modele.name,
              IDTarif : this.state.reparation.idTarif,
              Reparation: this.state.reparation.Libelle,
              Prix : this.state.reparation.Prix,
              Nom : this.state.nom,
              Email : this.state.email,
              Tel : this.state.telephone_mobile,
              Marque :this.state.currentMarqueName,
              CP : this.state.cp ,
              GUID : this.state.GUID,
              IDSite : this.state.IDSite,
            }
        ).then(({data}) => {


          console.log(data.TarifTTC);

          this.setState({prixAffiche: data.TarifTTC,  IsAffichePrix: true});
        }).catch(function (error) {
          console.log("Error****:", error.message);
        });

        }

      }else {
        console.log("Erreur");
        console.log(err);
      }
    });
  }


  ChargerCreneauxDisponible(){

  }

 affichage(){

   const MarqueImageList_ = [
     {
       id: 1,
       image: require('assets/images/APPLE.jpg'),
       name: '  Apple  ',
     },

     {
       id: 2,
       image: require('assets/images/SAMSUNG.jpg'),
       name: 'Samsung',
     },
     {
       id: 3,
       image: require('assets/images/HUAWEI.jpg'),
       name: 'Huawei',
     },
     {
       id: 4,
       image: require('assets/images/autre.png'),
       name: 'Autre',
     }
   ]

   const ModeleListApple_ = [
     {
       id: 1,
       image: require('assets/images/modele/APPLE/IPHONE11.png'),
       name: 'iPhone 11',
       tarif: [
             {
              id: 'Ecran',
              Prix: '199',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 343,
            },


       ]
     },
     {
       id: 1,
       image: require('assets/images/modele/APPLE/IPHONE8.png'),
       name: 'iPhone SE 2',
       tarif: [
             {
              id: 'Ecran',
              Prix: '189',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 565,
            },


       ]
     },
     {
       id: 1,
       image: require('assets/images/modele/APPLE/IPHONEX.png'),
       name: 'iPhone X',
       tarif: [
             {
              id: 'Ecran',
              Prix: '199',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 13,
            },
             {
              id: 'Batterie',
              Prix: '79',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 141,
             },
             {
              id: 'vitre_arriere',
              Prix: '119',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 237,
             },
       ]
     },
     {
       id: 2,
       image: require('assets/images/modele/APPLE/IPHONEXR.png'),
       name: 'iPhone Xr',
       tarif: [
             {
              id: 'Ecran',
              Prix: '199',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 14,
            },
             {
              id: 'vitre_arriere',
              Prix: '119',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 238,
             },
       ]
     },
     {
       id: 3,
       image: require('assets/images/modele/APPLE/IPHONEXS.png'),
       name: 'iPhone Xs',
       tarif: [
             {
              id: 'Ecran',
              Prix: '249',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 15,
            },
             {
              id: 'vitre_arriere',
              Prix: '129',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 239,
             },
       ]
     },
     {
       id: 4,
       image: require('assets/images/modele/APPLE/IPHONEXSMAX.png'),
       name: 'iPhone XS Max',
       tarif: [
             {
              id: 'Ecran',
              Prix: '299',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 16,
            },
             {
              id: 'vitre_arriere',
              Prix: '139',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 240,
             },
       ]

     },
     {
       id: 5,
       image: require('assets/images/modele/APPLE/IPHONE8.png'),
       name: 'iPhone 8',
       tarif: [
             {
              id: 'Ecran',
              Prix: '109',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 11,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 139,
             },
             {
              id: 'vitre_arriere',
              Prix: '99',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 235,
             },
       ]

     },
     {
       id: 6,
       image: require('assets/images/modele/APPLE/IPHONE8PLUS.png'),
       name: 'iPhone 8 Plus',
       tarif: [
             {
              id: 'Ecran',
              Prix: '119',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 12,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 140,
             },
             {
              id: 'vitre_arriere',
              Prix: '109',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 236,
             },
       ]
     },
     {
       id: 7,
       image: require('assets/images/modele/APPLE/IPHONE7.png'),
       name: 'iPhone 7',
       tarif: [
             {
              id: 'Ecran',
              Prix: '99',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 9,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 137,
             }
       ]

     },
     {
       id: 8,
       image: require('assets/images/modele/APPLE/IPHONE7PLUS.png'),
       name: 'iPhone 7 Plus',
       tarif: [
             {
              id: 'Ecran',
              Prix: '109',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 10,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 138,
             }
       ]

     },
     {
       id: 9,
       image: require('assets/images/modele/APPLE/IPHONE6S.png'),
       name: 'iPhone 6s',
       tarif: [
             {
              id: 'Ecran',
              Prix: '79',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 7,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 133,
             }
       ]


     },
     {
       id: 10,
       image: require('assets/images/modele/APPLE/IPHONE6SPLUS.png'),
       name: 'iPhone 6s Plus',
       tarif: [
             {
              id: 'Ecran',
              Prix: '99',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 8,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 135,
             }
       ]


     },
     {
       id: 11,
       image: require('assets/images/modele/APPLE/IPHONE6.png'),
       name: 'iPhone 6',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 5,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 133,
             }
       ]

     },
     {
       id: 12,
       image: require('assets/images/modele/APPLE/IPHONE6PLUS.png'),
       name: 'iPhone 6 Plus',
       tarif: [
             {
              id: 'Ecran',
              Prix: '79',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 6,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 134,
             }
       ]

     },
     {
       id: 13,
       image: require('assets/images/modele/APPLE/IPHONE5S.png'),
       name: 'iPhone 5SE',
       tarif: [
             {
              id: 'Ecran',
              Prix: '59',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 4,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 132,
             }
       ]

     },
     {
       id: 14,
       image: require('assets/images/modele/APPLE/IPHONE5S.png'),
       name: 'iPhone 5s',
       tarif: [
             {
              id: 'Ecran',
              Prix: '59',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 3,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 131,
             }
       ]

     },
     {
       id: 15,
       image: require('assets/images/modele/APPLE/IPHONE5C.png'),
       name: 'iPhone 5c',
       tarif: [
             {
              id: 'Ecran',
              Prix: '59',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 2,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 130,
             }
       ]

     },
     {
       id: 16,
       image: require('assets/images/modele/APPLE/IPHONE5.png'),
       name: 'iPhone 5',
       tarif: [
             {
              id: 'Ecran',
              Prix: '59',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 1,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 129,
             }
       ]

     },
   ]

   const ModeleListSamsung = [
     {
       id: 1,
       image: require('assets/images/modele/SAMSUNG/GALAXYA3.png'),
       name: 'GALAXY A3',
       tarif: [
             {
              id: 'Ecran',
              Prix: '129',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 29,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 154,
             },

       ]
     },
     {
       id: 2,
       image: require('assets/images/modele/SAMSUNG/GALAXYA32016.png'),
       name: 'GALAXY A3 2016',
       tarif: [
             {
              id: 'Ecran',
              Prix: '149',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 30,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 154,
             },
             {
              id: 'vitre_arriere',
              Prix: '59',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 251,
             },
       ]
     },
     {
       id: 3,
       image: require('assets/images/modele/SAMSUNG/GALAXYA32017.png'),
       name: 'GALAXY A3 2017',
       tarif: [
             {
              id: 'Ecran',
              Prix: '139',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 31,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 156,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 252,
             },
       ]
     },

     {
       id: 4,
       image: require('assets/images/modele/SAMSUNG/GALAXYA5.png'),
       name: 'GALAXY A5',
       tarif: [
             {
              id: 'Ecran',
              Prix: '149',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 33,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 158,
             },
             {
              id: 'vitre_arriere',
              Prix: '59',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 253,
             },
       ]
     },
     {
       id: 5,
       image: require('assets/images/modele/SAMSUNG/GALAXYA6.png'),
       name: 'GALAXY A6',
       tarif: [
             {
              id: 'Ecran',
              Prix: '109',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 36,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 161,
             },
             {
              id: 'vitre_arriere',
              Prix: '99',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 256,
             },
       ]
     },
     {
       id: 6,
       image: require('assets/images/modele/SAMSUNG/GALAXYA6PLUS.png'),
       name: 'GALAXY A6 PLUS',
       tarif: [
             {
              id: 'Ecran',
              Prix: '129',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 37,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 162,
             },
             {
              id: 'vitre_arriere',
              Prix: '99',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 257,
             },
       ]
     },

     {
       id: 7,
       image: require('assets/images/modele/SAMSUNG/GALAXYA8.png'),
       name: 'GALAXY A8',
       tarif: [
             {
              id: 'Ecran',
              Prix: '129',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 49,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 171,
             },
             {
              id: 'vitre_arriere',
              Prix: '79',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 261,
             },
       ]
     },

     {
       id: 8,
       image: require('assets/images/modele/SAMSUNG/GALAXYA9.png'),
       name: 'GALAXY A9',
       tarif: [

             {
              id: 'vitre_arriere',
              Prix: '99',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 263,
             },
       ]
     },

     {
       id: 9,
       image: require('assets/images/modele/SAMSUNG/GALAXYA10.png'),
       name: 'GALAXY A10',
       tarif: [
             {
              id: 'Ecran',
              Prix: '89',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 42,
            },

       ]
     },


     {
       id: 10,
       image: require('assets/images/modele/SAMSUNG/GALAXYA20E.png'),
       name: 'GALAXY A20E',
       tarif: [
             {
              id: 'Ecran',
              Prix: '89',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 43,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 167,
             },
       ]
     },
     {
       id: 11,
       image: require('assets/images/modele/SAMSUNG/GALAXYA40.png'),
       name: 'GALAXY A40',
       tarif: [
             {
              id: 'Ecran',
              Prix: '139',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 46,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 168,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 259,
             },
       ]
     },
     {
       id: 12,
       image: require('assets/images/modele/SAMSUNG/GALAXYA50.png'),
       name: 'GALAXY A50',
       tarif: [
             {
              id: 'Ecran',
              Prix: '139',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 47,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 169,
             },

       ]
     },
     {
       id: 13,
       image: require('assets/images/modele/SAMSUNG/GALAXYA70.png'),
       name: 'GALAXY A70',
       tarif: [
             {
              id: 'Ecran',
              Prix: '199',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 48,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 170,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 260,
             },
       ]
     },

     {
       id: 14,
       image: require('assets/images/modele/SAMSUNG/GALAXYA80.png'),
       name: 'GALAXY A80',
       tarif: [
             {
              id: 'Ecran',
              Prix: '179',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 51,
            },

             {
              id: 'vitre_arriere',
              Prix: '79',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 262,
             },
       ]
     },
     {
       id: 15,
       image: require('assets/images/modele/SAMSUNG/GALAXYA52016.png'),
       name: 'GALAXY A5 2016',
       tarif: [
             {
              id: 'Ecran',
              Prix: '149',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 33,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 158,
             },
             {
              id: 'vitre_arriere',
              Prix: '59',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 253,
             },
       ]
     },

     {
       id: 16,
       image: require('assets/images/modele/SAMSUNG/GALAXYA52017.png'),
       name: 'GALAXY A5 2017',
       tarif: [
             {
              id: 'Ecran',
              Prix: '149',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 34,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 159,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 254,
             },
       ]
     },

     {
       id: 17,
       image: require('assets/images/modele/SAMSUNG/GALAXYA72015.png'),
       name: 'GALAXY A7 2015',
       tarif: [
             {
              id: 'Ecran',
              Prix: '129',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 38,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 163,
             },

       ]
     },

     {
       id: 18,
       image: require('assets/images/modele/SAMSUNG/GALAXYA72018.png'),
       name: 'GALAXY A7 2018',
       tarif: [
             {
              id: 'Ecran',
              Prix: '109',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 41,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 166,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 258,
             },
       ]
     },


     {
       id: 20,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ4.png'),
       name: 'GALAXY J4',
       tarif: [
             {
              id: 'Ecran',
              Prix: '139',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 55,
            },

       ]
     },
     {
       id: 21,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ4PLUS.png'),
       name: 'GALAXY J4 PLUS',
       tarif: [
             {
              id: 'Ecran',
              Prix: '99',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 56,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 175,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 264,
             },
       ]
     },
     {
       id: 22,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ5.png'),
       name: 'GALAXY J5 2015',
       tarif: [
             {
              id: 'Ecran',
              Prix: '79',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 57,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 176,
             },

       ]
     },

     {
       id: 23,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ6.png'),
       name: 'GALAXY J6',
       tarif: [
             {
              id: 'Ecran',
              Prix: '99',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 60,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 179,
             },

       ]
     },
     {
       id: 24,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ6PLUS.png'),
       name: 'GALAXY J6 PLUS',
       tarif: [
             {
              id: 'Ecran',
              Prix: '89',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 61,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 180,
             },
             {
              id: 'vitre_arriere',
              Prix: '79',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 266,
             },
       ]
     },
     {
       id: 25,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ32016.png'),
       name: 'GALAXY J3 2016',
       tarif: [
             {
              id: 'Ecran',
              Prix: '89',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 53,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 173,
             },

       ]
     },
     {
       id: 26,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ32017.png'),
       name: 'GALAXY J3 2017',
       tarif: [
             {
              id: 'Ecran',
              Prix: '99',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 54,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 174,
             },

       ]
     },
     {
       id: 27,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ52016.png'),
       name: 'GALAXY J5 2016',
       tarif: [
             {
              id: 'Ecran',
              Prix: '109',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 58,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 177,
             },

       ]
     },
     {
       id: 28,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ52017.png'),
       name: 'GALAXY J5 2017',
       tarif: [
             {
              id: 'Ecran',
              Prix: '139',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 59,
            },
             {
              id: 'Batterie',
              Prix: '59',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 178,
             },
             {
              id: 'vitre_arriere',
              Prix: '79',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 265,
             },
       ]
     },
     {
       id: 29,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ72016.png'),
       name: 'GALAXY J7 2016',
       tarif: [
             {
              id: 'Ecran',
              Prix: '139',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 62,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 181,
             },

       ]
     },
     {
       id: 30,
       image: require('assets/images/modele/SAMSUNG/GALAXYJ72017.png'),
       name: 'GALAXY J7 2017',
       tarif: [
             {
              id: 'Ecran',
              Prix: '159',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 63,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 182,
             },
             {
              id: 'vitre_arriere',
              Prix: '79',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 267,
             },
       ]
     },
     {
       id: 31,
       image: require('assets/images/modele/SAMSUNG/GALAXYNOTE4.png'),
       name: 'GALAXY NOTE 4',
       tarif: [
             {
              id: 'Ecran',
              Prix: '179',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 24,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 149,
             },

       ]
     },
     {
       id: 32,
       image: require('assets/images/modele/SAMSUNG/GALAXYNOTE5.png'),
       name: 'GALAXY NOTE 5',
       tarif: [
             {
              id: 'Ecran',
              Prix: '249',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 25,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 150,
             },

       ]
     },
     {
       id: 33,
       image: require('assets/images/modele/SAMSUNG/GALAXYNOTE8.png'),
       name: 'GALAXY NOTE 8',
       tarif: [
             {
              id: 'Ecran',
              Prix: '289',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 26,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 151,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 248,
             },
       ]
     },
     {
       id: 34,
       image: require('assets/images/modele/SAMSUNG/GALAXYNOTE9.png'),
       name: 'GALAXY NOTE 9',
       tarif: [
             {
              id: 'Ecran',
              Prix: '319',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 27,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 152,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 249,
             },
       ]
     },
     {
       id: 35,
       image: require('assets/images/modele/SAMSUNG/GALAXYNOTE10.png'),
       name: 'GALAXY NOTE 10',
       tarif: [
             {
              id: 'Ecran',
              Prix: '329',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 28,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 153,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 250,
             },
       ]
     },
     {
       id: 36,
       image: require('assets/images/modele/SAMSUNG/GALAXYS8.png'),
       name: 'GALAXY S8',
       tarif: [
             {
              id: 'Ecran',
              Prix: '229',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 17,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 142,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 241,
             },
       ]
     },
     {
       id: 37,
       image: require('assets/images/modele/SAMSUNG/GALAXYS8P.png'),
       name: 'GALAXY S8P',
       tarif: [
             {
              id: 'Ecran',
              Prix: '299',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 18,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 143,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 242,
             },
       ]
     },
     {
       id: 38,
       image: require('assets/images/modele/SAMSUNG/GALAXYS9.png'),
       name: 'GALAXY S9',
       tarif: [
             {
              id: 'Ecran',
              Prix: '249',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 19,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 144,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 243,
             },
       ]
     },
     {
       id: 39,
       image: require('assets/images/modele/SAMSUNG/GALAXYS9P.png'),
       name: 'GALAXY S9P',
       tarif: [
             {
              id: 'Ecran',
              Prix: '329',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 20,
            },
             {
              id: 'Batterie',
              Prix: '69',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 145,
             },
             {
              id: 'vitre_arriere',
              Prix: '69',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 244,
             },
       ]
     },
     {
       id: 40,
       image: require('assets/images/modele/SAMSUNG/GALAXYS10.png'),
       name: 'GALAXY S10',
       tarif: [
             {
              id: 'Ecran',
              Prix: '349',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 21,
            },
             {
              id: 'Batterie',
              Prix: '89',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 146,
             },
             {
              id: 'vitre_arriere',
              Prix: '89',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 245,
             },
       ]
     },
     {
       id: 41,
       image: require('assets/images/modele/SAMSUNG/GALAXYS10E.png'),
       name: 'GALAXY S10E',
       tarif: [
             {
              id: 'Ecran',
              Prix: '249',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 23,
            },
             {
              id: 'Batterie',
              Prix: '89',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 148,
             },
             {
              id: 'vitre_arriere',
              Prix: '89',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 247,
             },
       ]
     },
     {
       id: 42,
       image: require('assets/images/modele/SAMSUNG/GALAXYS10PLUS.png'),
       name: 'GALAXY S10 PLUS',
       tarif: [
             {
              id: 'Ecran',
              Prix: '369',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 22,
            },
             {
              id: 'Batterie',
              Prix: '89',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 147,
             },
             {
              id: 'vitre_arriere',
              Prix: '89',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 246,
             },
       ]
     },
   ]

   const ModeleListHuawei = [
     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8.png'),
       name: 'Huawei P8',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 64,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 183,
             },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8LITE.png'),
       name: 'Huawei P8 Lite',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 65,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 183,
             },
       ]
     },
     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8LITE.png'),
       name: 'Huawei P8 Lite 2017',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 66,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 185,
             },
             {
              id: 'vitre_arriere',
              Prix: '39',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 268,
             },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP9LITE.png'),
       name: 'Huawei P9',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 67,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 186,
             },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP9LITE.png'),
       name: 'Huawei P9 Lite',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 68,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 187,
             },
             {
              id: 'vitre_arriere',
              Prix: '39',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 269,
             },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P9PLUS.png'),
       name: 'Huawei P9 Plus',
       tarif: [
             {
              id: 'Ecran',
              Prix: '169',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 69,
            },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P10PLUS.png'),
       name: 'Huawei P10',
       tarif: [
             {
              id: 'Ecran',
              Prix: '79',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 70,
            },
             {
              id: 'Batterie',
              Prix: '39',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 188,
             },
             {
              id: 'vitre_arriere',
              Prix: '99',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 270,
             },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEI_P10LITE.png'),
       name: 'Huawei P10 Lite',
       tarif: [
             {
              id: 'Ecran',
              Prix: '69',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 71,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 189,
             },
             {
              id: 'vitre_arriere',
              Prix: '89',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 27,
             },
       ]
     },



     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP20.png'),
       name: 'Huawei P20',
       tarif: [
             {
              id: 'Ecran',
              Prix: '89',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 73,
            },
             {
              id: 'Batterie',
              Prix: '49',
              Libelle: 'Remplacement batterie',
              image: require('assets/images/modele/REPARATION/batterie.png'),
              idTarif : 191,
             },
             {
              id: 'vitre_arriere',
              Prix: '39',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 272,
             },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP20LITE.png'),
       name: 'Huawei P20 Lite',
       tarif: [
             {
              id: 'Ecran',
              Prix: '89',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 74,
            },

             {
              id: 'vitre_arriere',
              Prix: '59',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 273,
             },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP20PRO.png'),
       name: 'Huawei P20 Pro',
       tarif: [
             {
              id: 'Ecran',
              Prix: '299',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 75,
            },

             {
              id: 'vitre_arriere',
              Prix: '49',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 274,
             },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P30.png'),
       name: 'Huawei P30',
       tarif: [
             {
              id: 'Ecran',
              Prix: '249',
              Libelle: 'Remplacement Ecran',
              image: require('assets/images/modele/APPLE/IPHONEX.png'),
              idTarif : 76,
            },

             {
              id: 'vitre_arriere',
              Prix: '89',
              Libelle: ' Remplacement vitre arrière ',
              image: require('assets/images/modele/REPARATION/vitre_arr.png'),
              idTarif : 275,
             },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P30LITE.png'),
       name: 'Huawei P30 Lite',
       tarif: [
         {
          id: 'Ecran',
          Prix: '119',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 77,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 192,
         },
         {
          id: 'vitre_arriere',
          Prix: '59',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 276,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP30PRO.png'),
       name: 'Huawei P30 Pro',
       tarif: [
         {
          id: 'Ecran',
          Prix: '349',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 78,
        },

         {
          id: 'vitre_arriere',
          Prix: '49',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 277,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8.png'),
       name: 'Huawei P Smart',
       tarif: [
         {
          id: 'Ecran',
          Prix: '89',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 79,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 193,
         },
         {
          id: 'vitre_arriere',
          Prix: '49',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 278,
         },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/PSMART2019.png'),
       name: 'Huawei P Smart 2019',
       tarif: [
         {
          id: 'Ecran',
          Prix: '99',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 80,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 194,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 179,
         },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIPSMARTPLUS.png'),
       name: 'Huawei P Smart Plus',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 81,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 49,
         },
         {
          id: 'vitre_arriere',
          Prix: '59',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 69,
         },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIPSMARTPLUS.png'),
       name: 'Huawei P Smart Plus 2019',
       tarif: [
         {
          id: 'Ecran',
          Prix: '99',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 82,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 196,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 281,
         },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIPSMARTPLUS.png'),
       name: 'Huawei P Smart Z',
       tarif: [
         {
          id: 'Ecran',
          Prix: '159',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 83,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 197,
         },
         {
          id: 'vitre_arriere',
          Prix: '59',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 282,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/MATE8.png'),
       name: 'Huawei Mate 8',
       tarif: [
         {
          id: 'Ecran',
          Prix: '99',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 84,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 198,
         },

       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/MATE9.png'),
       name: 'Huawei Mate 9',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 85,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 199,
         },

       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/MATE10.png'),
       name: 'Huawei 10 Lite',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 86,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 200,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 283,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIMATE10PRO.png'),
       name: 'Huawei Mate 10 PRO',
       tarif: [
         {
          id: 'Ecran',
          Prix: '249',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 87,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 201,
         },
         {
          id: 'vitre_arriere',
          Prix: '59',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 284,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P30LITE.png'),
       name: 'Huawei MATE 20',
       tarif: [
         {
          id: 'Ecran',
          Prix: '169',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 88,
        },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P30LITE.png'),
       name: 'Huawei MATE 20 Pro',
       tarif: [
         {
          id: 'Ecran',
          Prix: '329',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 90,
        },

         {
          id: 'vitre_arriere',
          Prix: '149',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 286,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/P30LITE.png'),
       name: 'Huawei Mate 20 X',
       tarif: [
         {
          id: 'Ecran',
          Prix: '399',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 91,
        },
         {
          id: 'Batterie',
          Prix: '59',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 203,
         },

       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8LITE.png'),
       name: 'Huawei Honor 4A',
       tarif: [
         {
          id: 'Ecran',
          Prix: '99',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 92,
        },


       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR5C.png'),
       name: 'Huawei Mate 5C',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 93,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 204,
         },

       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR5X.png'),
       name: 'Huawei Mate 5X',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 94,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 205,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR6A.png'),
       name: 'Huawei Mate 6a',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 95,
        },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR6X.png'),
       name: 'Huawei Honor 6X',
       tarif: [
         {
          id: 'Ecran',
          Prix: '89',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 96,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 206,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 287,
         },
       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR6C.png'),
       name: 'Huawei Honor 6C',
       tarif: [
         {
          id: 'Ecran',
          Prix: '199',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 97,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 207,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR7X.png'),
       name: 'Huawei Honor 7X',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 98,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 208,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 288,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR7C.png'),
       name: 'Huawei Honor 7C',
       tarif: [
         {
          id: 'Ecran',
          Prix: '129',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 99,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 209,
         },

       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR7.png'),
       name: 'Huawei Honor 7',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 99,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 209,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR7A.png'),
       name: 'Huawei Honor 7A',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 101,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 211,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR8.png'),
       name: 'Huawei Honor 8',
       tarif: [
         {
          id: 'Ecran',
          Prix: '119',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 102,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 212,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 289,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR8X.png'),
       name: 'Huawei Honor 8X',
       tarif: [
         {
          id: 'Ecran',
          Prix: '119',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 103,
        },

         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 290,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR8A.png'),
       name: 'Huawei Honor 8A',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 104,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 213,
         },

       ]
     },


     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8LITE.png'),
       name: 'Huawei Honor 8 Lite',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 105,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 214,
         },
         {
          id: 'vitre_arriere',
          Prix: '39',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 291,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR8PRO.png'),
       name: 'Huawei Honor 8 Pro',
       tarif: [
         {
          id: 'Ecran',
          Prix: '159',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 106,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 215,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR9.png'),
       name: 'Huawei Honor 9',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 107,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 216,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR9.png'),
       name: 'Huawei Honor 9X',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 108,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 217,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIY52018.png'),
       name: 'Huawei Honor 9X Lite',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 109,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 218,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR10LITE.png'),
       name: 'Huawei Honor 10',
       tarif: [
         {
          id: 'Ecran',
          Prix: '129',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 110,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 219,
         },
         {
          id: 'vitre_arriere',
          Prix: '99',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 292,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONOR10LITE.png'),
       name: 'Huawei Honor 10 Lite',
       tarif: [
         {
          id: 'Ecran',
          Prix: '99',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 110,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 220,
         },
         {
          id: 'vitre_arriere',
          Prix: '49',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 293,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONORVIEW10.png'),
       name: 'Huawei Honor 10 View',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 112,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 221,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONORVIEW10.png'),
       name: 'Huawei Honor 20',
       tarif: [
         {
          id: 'Ecran',
          Prix: '159',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 113,
        },

         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 294,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONORG8.png'),
       name: 'Huawei Honor View 20',
       tarif: [
         {
          id: 'Ecran',
          Prix: '129',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 114,
        },

         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 295,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONORPLAY.png'),
       name: 'Huawei Honor Play',
       tarif: [
         {
          id: 'Ecran',
          Prix: '89',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 115,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 222,
         },
         {
          id: 'vitre_arriere',
          Prix: '69',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 296,
         },
       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIP8LITE.png'),
       name: 'Huawei Honor G7',
       tarif: [
         {
          id: 'Ecran',
          Prix: '59',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 116,
        },
         {
          id: 'Batterie',
          Prix: '39',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 223,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HONORG8.png'),
       name: 'Huawei Honor G8',
       tarif: [
         {
          id: 'Ecran',
          Prix: '59',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 117,
        },
         {
          id: 'Batterie',
          Prix: '39',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 224,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y9.png'),
       name: 'Huawei Y9 2018',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 118,
        },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIY72018.png'),
       name: 'Huawei Y7 2018',
       tarif: [
         {
          id: 'Ecran',
          Prix: '129',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 119,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 225,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y72019.png'),
       name: 'Huawei Y7 2019',
       tarif: [
         {
          id: 'Ecran',
          Prix: '129',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 120,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 226,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y62017.png'),
       name: 'Huawei Y6 2017',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 121,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 227,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y62017.png'),
       name: 'Huawei Y6 2018',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 122,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 228,
         },
         {
          id: 'vitre_arriere',
          Prix: '49',
          Libelle: ' Remplacement vitre arrière ',
          image: require('assets/images/modele/REPARATION/vitre_arr.png'),
          idTarif : 297,
         },

       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y62019.png'),
       name: 'Huawei Y6 2019',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 123,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 229,
         },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIY6PRO2017.png'),
       name: 'Huawei Y6 Pro',
       tarif: [
         {
          id: 'Ecran',
          Prix: '59',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 124,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 230,
         },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y5-2.png'),
       name: 'Huawei Y5 II ',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 125,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 231,
         },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/HUAWEIY52018.png'),
       name: 'Huawei Y5 2018 ',
       tarif: [
         {
          id: 'Ecran',
          Prix: '109',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 126,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 232,
         },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/Y52019.png'),
       name: 'Huawei Y5 2019 ',
       tarif: [
         {
          id: 'Ecran',
          Prix: '79',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 127,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 233,
         },


       ]
     },

     {
       id: 1,
       image: require('assets/images/modele/HUAWEI/NOVA.png'),
       name: 'Huawei Nova ',
       tarif: [
         {
          id: 'Ecran',
          Prix: '69',
          Libelle: 'Remplacement Ecran',
          image: require('assets/images/modele/APPLE/IPHONEX.png'),
          idTarif : 128,
        },
         {
          id: 'Batterie',
          Prix: '49',
          Libelle: 'Remplacement batterie',
          image: require('assets/images/modele/REPARATION/batterie.png'),
          idTarif : 234,
         },


       ]
     },

   ]

   console.log('Logcurrent ' + this.state.current);
   console.log('LogcurrentMarque ' + this.state.currentMarque);
   switch(this.state.current) {
    case 0:
      this.setState({marqueImageList: this.state.tabMarqueImageList});
      break;
    case 1:
      switch(this.state.currentMarque) {
        case 1:
          this.setState({marqueImageList: this.state.tabModeleListApple});
          this.setState({IsAutre: false});
          break;
        case 2:
        this.setState({marqueImageList: ModeleListSamsung});
        this.setState({IsAutre: false});
        break;

        case 3:
        this.setState({marqueImageList: ModeleListHuawei});
        this.setState({IsAutre: false});
        break;

        case 4:
        this.setState({IsAutre: true});

        break;
      }

  }
 }

imageClick(e) {
    const current = this.state.current + 1;

    console.log('event ' + e.id  + '  Current:' + current);

    switch (current) {
      case 0: // marque
      console.log(0);
        break;

      case 1: // modèle
      console.log(1)
        this.state.currentMarque = e.id;
        console.log('currentMarque ' + e.name);

        this.state.currentMarqueName = e.name;

        break;

      case 2: // rep
          this.state.modele =e;
          this.state.tarif = e.tarif;
        break;

      case 3:
        this.state.reparation = e;
        console.log(this.state.reparation);
      break;

    }

    this.state.current= current;
    this.affichage();
    this.setState({current});
  }

next() {
    const current = this.state.current + 1;
    this.setState({current});
  }

nextAutre() {
      const current = this.state.current + 2;
      this.setState({current});
    }

  prev() {
    this.state.IsAffichePrix = false;

    console.log('Etat ' + this.state.current);
    if(this.state.current >= 3){
      console.log('Sup = 4');
      const current = this.state.current - 2;
      this.state.current = current;
    }else{
      const current = this.state.current - 1;
      this.state.current = current;
    }

    this.setState({IsAutre: false});

    this.affichage();
  }

 componentDidMount() {
  const location = window.location.href;

  console.log('Location ' + location);

  const path = location.split('/');

  const GUID = path[path.length - 1];

  //console.log('GUID ' + GUID);

  let url = this.props.location.search;



  console.log(' URL ' + url);



  var marque = '';

  if(url === '?marque=apple' ){
    const current = 1;
    this.state.currentMarque = 1;
    this.state.currentMarqueName = 'Apple';
    this.state.current= 1;
    this.affichage();
    this.setState({current});

  }else if (url === '?marque=apple_arriere'){
    const current = 1;
    this.state.currentMarque = 1;
    this.state.currentMarqueName = 'Apple';
    this.state.current= 1;

    var tabVitreArriere = [];

    for (var i = 0; i < this.state.tabModeleListApple.length; i++) {
      for (var j = 0; j < this.state.tabModeleListApple[i].tarif.length; j++) {
        if(this.state.tabModeleListApple[i].tarif[j].id == 'vitre_arriere'){
          console.log(this.state.tabModeleListApple[i].tarif[j])
          tabVitreArriere.push(this.state.tabModeleListApple[i]);
        }
      }
    }
    this.state.tabModeleListApple= tabVitreArriere;

    this.affichage();
    this.setState({current});


  }else if (url === '?marque=samsung'){
    const current = 1;
    this.state.currentMarque = 2;
    this.state.currentMarqueName = 'Samsung';
    this.state.current= 1;
    this.affichage();
    this.setState({current});


}else if (url === '?marque=huawei'){
  const current = 1;
  this.state.currentMarque = 3;
  this.state.currentMarqueName = 'Huawei';
  this.state.current= 1;
  this.affichage();
  this.setState({current});

}else if (url === '?marque=autre'){
  const current = 1;
  this.state.currentMarque = 4;
  this.state.currentMarqueName = 'autre';
  this.state.current= 1;
  this.affichage();
  this.setState({current});


}else if (url === '?ville=paris15'){

  this.state.IDSite = 2;



}else if (url.includes('?pr=') ){
  this.state.GUID = url.substring(4 ,url.length);


}else{

  }





//window.location.replace("/")

  this.affichage();







}

componentDidUpdate() {

  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {span: 50},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 50},
        sm: {span: 16},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const {getFieldDecorator} = this.props.form;

    const steps = [
        {
          title: 'Marque',
          content: '',
        },
        {
          title: 'Modèle',
          content: '',
        },
        {
          title: 'Réparation',
          content: '',
        },
        {
          title: 'Devis',
          content: '',
        }
    ];

    return (

      <div style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }} className="gx-app-login-wrap">

      <div >
        <br/><br/>  <br/><br/>

        <div className="gx-app-login-container ant-col ant-col-6 gx-vertical-align-middle ">

        <WidgetHeader styleName="gx-flex-row "
                      title="Que pouvons-nous réparer pour vous aujourd’hui ?"
                      extra={<span><i
                      className="icon icon-long-arrow-right  gx-ml-2 gx-d-inline-flex "/></span>}/>

          <Card       className="gx-text-center"
                      title="Sélectionner l'image de votre choix"
                      extra={<span>

                                </span>}>
                        <br/><br/>

            <Steps current={this.state.current}>
              {steps.map(item => <Step key={item.title} title={item.title}/>)}
            </Steps>
            <div className="steps-content">{steps[this.state.current].content}</div>
            <br/>

            {
              this.state.current < 2 && this.state.IsAutre === false
              &&

            <ul className="gx-agents-list">

              <br/><br/>
              {this.state.marqueImageList.map((user, index) =>
                <li key={index}>
                  <div className="gx-text-center">
                    <div className="gx-profileon-thumb">
                      <img alt="..." src={user.image} onClick={() => this.imageClick(user)} className="gx-rounded-lg gx-w-75"/>
                      <span className="gx-text-light gx-fs-xl gx-justify-content-center"> {user.name} </span>
                    </div>
                    <div className="gx-profileon-content">

                    </div>
                  </div>
                </li>
              )
              }
            </ul>
            }

            {
              this.state.current === 2 && this.state.IsAutre === false
              &&

              <ul className="gx-agents-list">
                <br/><br/>
                {this.state.tarif.map((user, index) =>
                  <li key={index}>
                    <div className="gx-text-center">
                      <div className="gx-profileon-thumb">
                        <img alt="..." src={user.image} onClick={() => this.imageClick(user)} className="gx-rounded-lg gx-w-75"/>
                      </div>
                      <div>
                      <span className="gx-text-light gx-fs-lg gx-justify-content-center"> {user.Libelle} </span>
                      </div>
                      <div className="gx-profileon-content">

                      </div>
                    </div>
                    <br/><br/>
                  </li>
                )
                }
              </ul>

            }

            {
              this.state.IsAutre === true
              &&

              <ul className="gx-agents-list">
                <Card className="gx-card ant-col-24" title="Fais nous ta demande !">

                <TextArea
                          id="autre"
                          onChange={this.updateMessageValue.bind(this)}
                          rows={8}
                          multiline="true"
                          placeholder="Fais ta demande ici !"
                          autosize={{minRows: 8, maxRows: 8}}
                          />
                </Card>
              </ul>
            }


            {
              this.state.current === 3 && this.state.IsAffichePrix === false
              &&

              <ul className="gx-agents-list">


              <Card className="gx-card ant-col ant-col-24" title="  Dernière étape !">

              <h2>  Laisses nous tes coordonnées pour récupérer ton devis</h2>

              <br/>
              <h2>  Promis c’est juste pour te recontacter pour faire la réparation ;) </h2>
                <br/>
                <Form onSubmit={this.handleSubmit}>

                <FormItem
                  {...formItemLayout}
                  label="Nom"
                >
                  {getFieldDecorator('nom')(
                    <Input id="nom"/>
                  )}
                </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="E-mail"
                  >
                    {getFieldDecorator('email', {
                      rules: [{
                        type: 'email', message: 'Entrer une adresse E-mail valide',
                      }, {
                        required: true, message: 'Entrer votre E-mail!',
                      }], onChange:this.handleChange,
                    })(
                      <Input id="email1"/>
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="N° téléphone"
                  >
                    {getFieldDecorator('telephone_mobile', {
                      rules: [{required: false, message: 'Entrer votre numéro de téléphone!'}],
                    })(
                      <Input  style={{width: '100%'}}
                      />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Code Postal"
                  >
                    {getFieldDecorator('code_postal', {
                      rules: [{required: false, message: 'Entrer votre code postal!'}],
                    })(
                      <Input  style={{width: '100%'}}
                      />
                    )}
                  </FormItem>


                  <FormItem {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Envoyer</Button>
                  </FormItem>
                </Form>
              </Card>


              </ul>
            }


            {
              this.state.IsAffichePrix === true  && this.state.IsAutre === false
              &&

              <ul className="gx-agents-list">
              <br/>
              <br/>
              <br/>

              <h1>   Votre réparation est estimée à {this.state.prixAffiche}€, nous vous contactons très vite!!</h1>

              <br/>
              <br/>
              <br/>
              </ul>
            }

            <div className="steps-action">
              {
                this.state.current < steps.length - 1
              }

              {
                this.state.IsAutre === false && this.state.current > 5
                &&
                <Button style={{marginLeft: 8}} onClick={() => this.ChargerCreneauxDisponible()}>
                  Prendre rendez-vous !
                </Button>

              }

              <br/>
              {
                this.state.current > 0
                &&
                <Button style={{marginLeft: 8}} onClick={() => this.prev()}>
                  Précédent
                </Button>
              }


              {
                this.state.IsAutre === true && this.state.current < 3
                &&
                <Button style={{marginLeft: 8}} onClick={() => this.nextAutre()}>
                  Suivant
                </Button>
              }





                <br/>  <br/>

            </div>
      </Card>
      <br/><br/><br/><br/>
      </div>
      <br/><br/><br/>
      </div>
      <br/>
    </div>
    );
  }
}
// <Button type="primary" onClick={() => message.success('Processing complete!')}>Valider</Button>
const WrappedNormalLoginForm = Form.create()(Devis);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userDevis})(WrappedNormalLoginForm);
